import React from 'react'
import Footer from '../components/Footer'

const CarAccidents = () => {

    return (
        <div className="MalpraticeHolder">
            {/* <Header /> */}
            <div className="MalpraticeInner">
                <div className="TextHolder">
                    <h1 className="MainHeading">
                    Philadelphia Car Accident Lawyer
                    </h1>
                  
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        If you were injured in a car crash in Philadelphia due to someone’s reckless or wrongful behavior, your top priority must be to focus on your physical and mental healing process. In the tragic event that you lost a loved one in a car accident, you are likely more concerned with mourning the loss and comforting your relatives than figuring out how you’ll recover financially.

</p>
                        <p className="TextPassage">
                        The Levin Injury Firm’s knowledgeable and compassionate Philadelphia car accident attorneys are here to help. We are experienced in managing the insurance and legal processes for those who have been injured by negligence, and you may be eligible for a substantial sum of money if you act quickly and cautiously. Car accidents can impose many financial strains and administrative difficulties, but you don’t have to face them alone — let us take the legal weight off your shoulders.

</p>
                    </div>

                    <h1 className="MainHeading">
                    Our Philadelphia Car Accident Lawyers Are Here to Help
                    </h1>
                    <h1 className="MainHeading">
                    Why Choose Us?
                    </h1>

                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        After a serious car accident in Philadelphia, you may not know where to turn for help. At The Lawyer Connection our Philadelphia car accident lawyers want to be the first – and only – call you ever need to make. Why? Because we have the experience and resources needed to tackle your case!


                        </p>
                       

                        <p className="TextPassage">
                        We aim to ensure you are fully compensated for your losses after a serious Philadelphia car accident. We focus on our clients and their unique situations to achieve successful outcomes. A member of our award-winning team of attorneys will meet personally with you to handle your car accident case from the start.

</p>
<p className="TextPassage">
Our track record of success speaks for itself. We have a history of securing multi-million dollar settlements and verdicts for our injured Philadelphia car accident clients. But don’t take our word for it! Look at what our past clients have to say about our law firm.

</p>
                    </div>

                    <h1 className="MainHeading">
                    Easily Located in Center City, Philadelphia
                    </h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        If you suffered an injury in a car accident anywhere in Philadelphia, you owe it to yourself to explore your legal options. To better assist you, our Philadelphia car accident lawyers can meet with you in our office, at your home, or anywhere convenient. We can even meet with you virtually during this time. Our office is conveniently located at 1500 John F. Kennedy Blvd at Two Penn Center. We are in the heart of downtown Philadelphia, just across the street from JFK Plaza and around the corner from City Hall. If you are taking public transportation to our office, you can exit at the 15th St. Trolley Station, the Suburban Station, or the City Hall Subway Station.


                        </p>

                        <p className="TextPassage">
                        Call us today at (215) 825-5183 or toll-free at (877) 825-8542, or contact or chat with us online to schedule a free consultation with a member of our team.

</p>

                        
                    </div>

                    <h1 className="MainHeading">Interested in Learning More?
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        If you are interested in learning more about your legal rights and options after a car accident, we invite you to come for a free consultation as soon as possible. Many injured accident victims ask us what they can do to help their case even before they come into our offices. To better assist you, we’ve compiled a checklist of things you can do after a Philadelphia car accident.

</p>
                       

                        <ul>
                            <li className="TextPassage">Obtain copies of your medical records
</li>

                            <li className="TextPassage">Obtain a copy of the police report
</li>

                            <li className="TextPassage">
                            Keep a recovery journal with detailed information
                            </li>
                            <li className="TextPassage">
                            Keep all receipts and medical expenses
                            </li>
                            <li className="TextPassage">
                            Attend all therapy sessions and doctor’s appointments
                                </li>
                            <li className="TextPassage">
                            Follow your physician’s treatment plan precisely
                            </li>

                            <li className="TextPassage">
                            Call your insurance company
                            </li>
                            <li className="TextPassage">
                            Do not give a recorded statement to insurers
                            </li>
                            <li className="TextPassage">
                            Do not get the car repaired quickly, as it is valuable evidence
                            </li>
                            <li className="TextPassage">
                            Gather any additional evidence or information that may help your case
                            </li>
                            <li className="TextPassage">
                            Stay off social media
                            </li>
                            <li className="TextPassage">
                            Never admit fault
                            </li>
                        </ul>
                      
                    </div>

                    <h1 className="MainHeading">How Can Our Philadelphia Car Accident Lawyers Help?
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Our car accident attorneys have a wide breadth of knowledge when it comes to the roads, courts, and insurance companies in Pennsylvania. With each successful case, we have gained valuable insight, which we use to assist our clients in getting the compensation they are due.

</p>
                        <p className="TextPassage">
                        Motor vehicle accidents can strip away your financial security and cause long-term consequences. The Lawyer Connection is available to assist car accident victims by fighting for the compensation they deserve. Our experienced Philadelphia car accident attorneys are here to provide support to those suffering from the loss of a loved one or significant injuries from a car accident.

</p>

<p className="TextPassage">
Our car accident attorneys have a wide breadth of knowledge when it comes to the roads, courts, and insurance companies in Pennsylvania. With each successful case, we have gained valuable insight, which we use to assist our clients in getting the compensation they are due.


</p>
<p className="TextPassage">
Motor vehicle accidents can strip away your financial security and cause long-term consequences. The Lawyer Connection is available to assist car accident victims by fighting for the compensation they deserve. Our experienced Philadelphia car accident attorneys are here to provide support to those suffering from the loss of a loved one or significant injuries from a car accident.

</p>
                       
                    </div>

                    <h1 className="MainHeading">How can we help?
</h1>
<h1 className="MainHeading">Our lawyers listen to you.

</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        The Lawyer Connection’s experienced team will get to know you and your family personally to determine how we can maximize the compensation you receive. We will listen to your story and goals for seeking legal advice to help you regain what you lost due to the car accident.


                        </p>
                      
                    </div>


                    <h1 className="MainHeading">Our attorneys investigate your case.
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Our experienced lawyers will thoroughly look into the details to determine what took place and who is responsible. We use our broad resources and legal skill to analyze all aspects of our clients’ car accident incidents. We try to ascertain that we have identified all individuals who could be held liable and seek the maximum allowable compensation under the law.
                        </p>
                      
                    </div>

                    <h1 className="MainHeading">Our lawyers uncover ALL forms of compensation.

</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        If the person at fault for your accident doesn’t have enough insurance coverage, The Lawyer Connection can help you look for other ways to seek compensation. Our team is experienced in finding the parties responsible for your injuries, regardless of how many there may be. We strive to help our clients get the justice they deserve.

</p>
                      
                    </div>

                    
                    <h1 className="MainHeading">Our law firm employs experts
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        To better understand the effects of auto accidents, we enlist the help of professionals such as accident reconstructionists and economic analysts. We also work with economists to calculate the potential loss of future earnings and with medical experts to determine the costs of long-term medical care for those who have sustained serious injuries.


</p>
<p className="TextPassage">
Through these measures, our clients are able to achieve a more comprehensive understanding of the financial implications of their crashes.



</p>
                      
                    </div>



                    <h1 className="MainHeading">Our lawyers negotiate with insurance companies.

</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Dealing with the insurance company is the last thing you should concern yourself with when you’re recovering from an automobile accident. At the Lawyer Connection our legal team is highly competent in negotiating with insurance companies and defending clients from insurers’ tactics to minimize their payouts. Our experienced car accident attorneys strive to attain the maximum possible settlement. When possible, we will liaise with insurance adjusters to make sure our clients receive the full amount they are due.



</p>
                    </div>


                    <h1 className="MainHeading">Our attorneys litigate cases in court.


</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        In some car accident cases, a personal injury lawsuit may be the best option to receive the justice you deserve. We are experienced trial lawyers who are ready to represent you in court and present your case to a jury.

</p>
                    </div>




                    <h1 className="MainHeading">How Much is My Philadelphia Car Accident Claim Worth?
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        No one can guarantee how much a claim is worth before beginning the legal processes that accompany it. However, your compensation could be significant depending on the extent of your injuries and other damages.

</p>
                        <p className="TextPassage">
                        At The Lawyer Connection we strive to make sure that you don’t have to suffer financially from a car accident that wasn’t your fault. The amount of compensation you might receive depends on the specifics of the incident, and while it can range from millions of dollars to tens of thousands, our goal is to secure you the maximum amount possible.

 </p> 
 <ul>
                            <li className="TextPassage">
                            The severity of your injuries
                            </li>

                            <li className="TextPassage">
                            Your age and health condition at the time of the accident

                            </li>

                            <li className="TextPassage">
                            Your life circumstances, such as whether you:
                            <ul>
                            <li className="TextPassage">
                            Had a job or career
                            </li>

                            <li className="TextPassage">
                            Supported or cared for family members

                            </li>

                            <li className="TextPassage">
                            Were active in the community

                            </li>
                        </ul>
                            </li>


                            <li className="TextPassage">
                            How the accident happened and who was at fault

                            </li>
                            <li className="TextPassage">
                            The potential difficulty and expense of proving your case

                            </li>
                            <li className="TextPassage">
                            The availability of insurance to cover your losses

                            </li>



                        </ul>
                        <p className="TextPassage">
                        At The Levin Firm, we understand that money cannot solve all the problems a car accident creates. It doesn’t bring back loved ones who were taken from us before their time. It doesn’t take away the physical pain of a back injury or the emotional difficulty of brain damage.


                        </p>
                        <p className="TextPassage">
                        However, money can help you and your family find stability after the tragedy. A monetary settlement can eliminate the mountains of bills that pile up, and it can pay for medical treatments and services that give you a chance to adjust to a “new normal.”

                        </p>
                    </div>

                    <h1 className="MainHeading">What Types of Damages Can I Recover From a Philadelphia Car Accident?
</h1>
<h1 className="MainHeading">Helping Philadelphia Car Accident Victims Seek the Compensation They Deserve
</h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        After a car accident in Philadelphia, you need a law firm on your side that can help you recover damages. The term “damages” refers to the victims’ losses. Compensation is available for these losses, either through a settlement or a judgment. Recovering damages after a car accident requires proving fault and establishing the losses you suffered. A Philadelphia car accident attorney can help you prove your case and obtain maximum compensation after a car crash.


                        </p>
                        <p className="TextPassage">
                        The damages available in Pennsylvania civil courts include:


                        </p>
                        <ul>
              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Medical bills.</span>This includes recovery for your past and future medical bills. Rehabilitation expenses, surgeries, treatment, doctor’s office visits, and disability costs are some of the damages you can recover.
              </li>

              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Lost wages.</span> An accident may cause you to miss valuable time at work. This can directly impact your ability to earn a living and pay your living expenses. Any settlement or judgment you receive should reimburse you for lost wages.
              </li>
              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Loss of future earnings</span>
                When your injuries result in a disability or long-term damage, you may not be able to work in your chosen profession. You can request payment for lost future earnings if you suffered a disability after a Philadelphia car accident.
              </li>

              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Property damage.</span>
                Car accidents often cause significant property damage. This can include damage to your car and other personal belongings in the vehicle, such as cell phones, laptops, and car seats. You could recover the full value of your car before the accident if your crash resulted in a complete loss.
              </li>
              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Pain and suffering.</span>This is one type of non-economic damage you can seek. Non-economic damages cover losses that are more difficult to quantify, including pain, suffering, and emotional distress.
              </li>
              <li className="TextPassage">
                <span style={{ fontWeight: 700 }}>Punitive damages. </span>Sometimes, a judge will order punitive damages. These types of damages are rare, but they seek to punish the negligent driver for their actions
              </li>
            </ul>
                        <p className="TextPassage">
                        Pennsylvania operates under modified comparative negligence law. This means that if you are more than 50% to blame for the accident, you cannot seek to recover damages. If you are found to share liability for the crash, your damages will be reduced by your percentage of fault.

</p>
<p className="TextPassage">
There are sometimes caps on the amount of compensation you can obtain. Our Pennsylvania car accident lawyers will review your case and help you determine how much you could receive if you file a Pennsylvania car accident lawsuit.

</p>
                    </div>


                    <h1 className="MainHeading">
                    Who Is to Blame For My Philadelphia Car Accident?
                    </h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        As lawyers who represent victims of Philadelphia car accidents, we spend a lot of time and energy thinking about fault, and for good reason. Under Pennsylvania law, who was at fault in a motor vehicle accident determines who owes damages to the victim and how much the victim should recover. As such, we focus on identifying who was at fault in a car accident as soon as possible.

</p>
                        </div>

                    <h1 className="MainHeading">
                    It All Usually Comes Down to Negligence
                    </h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        In the most basic sense, the person at fault for an accident is the one whose actions caused it to happen.

</p>
                        <p className="TextPassage">
                        Under Pennsylvania law, the person or entity at fault for an accident will be on the hook for paying damages to those injured only if that person or entity’s actions were wrongful. Now, most of the time, people don’t try to cause a car accident. (Obviously, if they do, they’ll be held liable.) Instead, legal liability for a car accident usually boils down to whether the person or entity at fault acted negligently in causing a motor vehicle accident.

</p>
<p className="TextPassage">
Though most people have heard lawyers use the word negligent, many may not fully understand what the term means.


</p>
<p className="TextPassage">
To prove someone was negligent in their actions, under Pennsylvania law, you must prove 4 key elements:


</p>
<ul>
              <li className="TextPassage">
              Had a duty of care to behave or act responsibly

              </li>

              <li className="TextPassage">
              Breached that duty of care by acting in a way that a reasonable person would expect to cause the victim harm
              </li>
              <li className="TextPassage">
              Harmed the victim through those actions
              </li>

              <li className="TextPassage">
              Caused the victim to suffer damages as a result of that harm
              </li>

             
            </ul>
<p className="TextPassage">
All drivers on Pennsylvania roads owe a duty of care to everyone else on the road, including other drivers, bicyclists, and pedestrians. That duty requires drivers to operate their vehicles in a reasonably safe manner that will avoid accidents and injuries to others.



</p>
<p className="TextPassage">
In Philadelphia and across the state, every driver must follow specific laws and regulations. These laws provide guidelines for what constitutes that duty of care. When a driver breaks those rules and gets into an accident, that violation is evidence that he or she was “negligent” behind the wheel.


</p>
                    </div>

                    <h1 className="MainHeading">
                    Our Philadelphia Car Accident Lawyer Can Find All Responsible Parties
                    </h1>
                    <h2 className="MainHeading">
                    The Importance in Proving Fault in an Accident
                    </h2>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Lawyers who represent victims of car accidents spend a lot of time and energy thinking about fault, and for good reason. Under Pennsylvania law, who was at fault in a motor vehicle accident determines who owes damages to the victim and how much the victim should recover. An experienced car accident attorney will usually focus on identifying who was at fault in a car accident as soon as possible.

</p>
                       
                    </div>

                    <h1 className="MainHeading">
                    Where Do Philadelphia Car Accidents Occur?
                    </h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        In one recent year, more than 28,000 people were involved in crashes in Philadelphia alone. Nearly 100 lost their lives in those crashes, and an additional 11,000 suffered an injury. In 55 percent of those accidents, aggressive driving was a factor. Distracted driving, speeding, and drunk driving all play a role in making the streets of Philadelphia dangerous.

</p>
<p className="TextPassage">
Where do Philadelphia car accidents occur most?
</p>
<p className="TextPassage">
According to recent data, some of the most dangerous streets and intersections in Philly include:

</p>
                        <ul>
                            <li className="TextPassage">Street Road
</li>

                            <li className="TextPassage">I-95
</li>

                            <li className="TextPassage">Delaware Expressway
</li>
                            <li className="TextPassage">
                            Route 1
                            </li>
                            <li className="TextPassage">
                            Roosevelt Boulevard
                            </li>
                            <li className="TextPassage">
                            Schuylkill Expressway
                            </li>
                            <li className="TextPassage">
                            Rt 611
                            </li>
                            <li className="TextPassage">
                            Broad Street
                            </li>
                            <li className="TextPassage">
                            Easton Road
                            </li>
                            <li className="TextPassage">
                            Old York Road
                            </li>
                        </ul>
                        <p className="TextPassage">
                        At The Lawyer Connection our Philadelphia car accident lawyers are proud to represent injured accident victims and families throughout Philadelphia. Some of the communities we serve include:

</p>
                        <p className="TextPassage">
                        Strawberry Mansion, Allegheny West, North Philadelphia, West Philadelphia, Lanning Square, Camden, Pennsauken Township, Cherry Hill, Port Richmond, Fairmount Park, Germantown, Kinsessing, Juniata, South Philadelphia, University City, Oxford, and more.

 </p>
                    </div>

                    <h1 className="MainHeading">
                    What Are the Common Causes of Car Accidents in Philadelphia?
                    </h1>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        In Philadelphia, there are many causes of car accidents. One of the most common reasons car accidents occur is because of driver negligence. Drivers who are irresponsible behind the wheel may engage in a variety of risky driving behaviors, including:


                        </p>
                        <ul>
                            <li className="TextPassage">
                            Driving while intoxicated by drugs or alcohol (DUI)

                            </li>

                            <li className="TextPassage">
                            Driving distracted
      </li>

                            <li className="TextPassage">
                            Speeding
 </li>
                            <li className="TextPassage">
                            Tailgating
         </li>
         <li className="TextPassage">
         Driving aggressively or unpredictably
         </li>
         <li className="TextPassage">
         Failing to obey traffic signals
         </li>
         <li className="TextPassage">
         Drowsy driving
         </li>
         <li className="TextPassage">
         Driving without a license
         </li>
         <li className="TextPassage">
         Road Rage
         </li>
                        </ul>
                        <p className="TextPassage">
                        In some cases, defective auto parts can cause serious accidents. Defective tires, steering components, and transmissions can cause devastating accidents with little warning. These cases are different from typical car accident cases because they involve product liability laws.


                        </p>
                       
                    </div>

                    <h1 className="MainHeading">
                    How Do You Prove Fault After a Philadelphia Car Accident?

                    </h1>
                    <h2 className="MainHeading">
                    Gathering Evidence After a Car Crash

                    </h2>
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        To prove negligence, our lawyers often need to gather independent evidence or show the other driver was at fault.


                        </p>
                        <p className="TextPassage">
                        For example, it may not be enough for you to testify that you saw the other driver texting just before the accident happened. Instead, our Philadelphia car accident lawyers will need to gather real, tangible evidence. This may include the other driver’s cellphone records to show the exact moment the driver sent or received a text. We may also need to track down other witnesses who saw the driver texting.

</p>
                        <p className="TextPassage">
                        Our experienced auto accident lawyer understands the kind of evidence necessary in Philadelphia courts to demonstrate that the other driver’s negligent actions caused an accident.

</p>

<ul>
              <li className="TextPassage">
              Photos from the crash scene showing the positioning of the vehicles, skid marks, damage, traffic signals, etc.

              </li>

              <li className="TextPassage">
              Statements from witnesses
              </li>
              <li className="TextPassage">
              Surveillance video from traffic cameras or nearby businesses
              </li>

              <li className="TextPassage">
              Driving records
              </li>

              <li className="TextPassage">
              Cellphone records
              </li>
              
              <li className="TextPassage">
              Analysis from accident reconstruction experts
              </li>
            </ul>
                    </div>










                    <h1 className="MainHeading">
                    What If I Am Partly to Blame For the Philadelphia Car Accident?

                    </h1>
                   
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        If you believe that you might be partly to blame for the car accident, do not worry. Unless you are more than 50 percent liable for the accident, you can still collect compensation. This is due to Philadelphia’s modified contributory negligence doctrine.


                        </p>
                        <p className="TextPassage">
                        Here’s how it works:


</p>
              

<ul>
              <li className="TextPassage">
              Under Pennsylvania law, a court will attribute a percentage of fault to each party in a car accident lawsuit.

              </li>

              <li className="TextPassage">
              So long as your share of fault is less than the total share of the fault borne by all defendants combined, the plaintiff can recover damages.
              </li>
              <li className="TextPassage">
              Your recovery from the defendant(s) will be reduced by the proportion you are found at fault.
              </li>

             
            </ul>
            <p className="TextPassage">
            The rules of modified comparative negligence can make calculating fault and damages even more complicated. Sometimes, for example, one of the defendants may be on the hook for 100 percent of the damages, even if more than one defendant was at fault.



</p>
<p className="TextPassage">
Always consult an experienced Philadelphia car accident attorney before taking action to seek damages from someone who caused a wreck—especially if someone tries to shift some of the blame to you. An attorney at The Lawyer Connection can help sort through the parties at fault to identify those who may be both most at fault and most capable of paying damages.



</p>
                    </div>














                    <h1 className="MainHeading">
                    What Are the Common Types of Car Accidents?

                    </h1>
                   
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Our Philadelphia Car Accident Lawyers Handle All Types of Car Accident Claims



                        </p>
                        <p className="TextPassage">
                        Just as our firm handles motor vehicle accidents caused by a wide variety of factors, we also represent clients who sustained their injuries and losses in every conceivable type of car accident. Here are some of the most common types of collisions and wrecks for which our team helps our clients recover compensation.



</p>
              

<ul>
              <li className="TextPassage">
              Rear-end Collisions

              </li>

              <li className="TextPassage">
              Side impact accidents or T-bone collisions
              </li>
              <li className="TextPassage">
              Head-on collisions
              </li>
              <li className="TextPassage">
              Single vehicle accidents
              </li>
              <li className="TextPassage">
              Collisions with fixed objects
              </li>
              <li className="TextPassage">
              Rollover crashes
              </li>
              <li className="TextPassage">
              Hit and run accidents
              </li>
              <li className="TextPassage">
              Ridesharing accidents
              </li>
              <li className="TextPassage">
              Uninsured and underinsured motorist crashes
              </li>

             
            </ul>
            <p className="TextPassage">
            At The Lawyer Connection, clients come to us after all types of car accidents. No matter whether your accident involved one, two, or 10 vehicles, we have the experience and know-how to help you fight for the compensation you deserve. Contact us today to schedule a free consultation.
</p>

                    </div>
























                    <h1 className="MainHeading">
                    What Types of Injuries Do Car Accidents in Philadelphia Cause?

                    </h1>
                    <h2 className="MainHeading">
                    We Help Injured Car Crash Survivors Like You!

                    </h2>
                   
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        Car accidents can result in serious and life-threatening injuries. Even accidents that occur at low speeds can result in serious harm and chronic disability.




                        </p>
                        <p className="TextPassage">
                        At The Lawyer Connection our Philadelphia car accident lawyers have helped numerous accident survivors who suffered serious car accident injuries, such as:




</p>
              

<ul>
              <li className="TextPassage">
              Traumatic brain injuries

              </li>

              <li className="TextPassage">
              Spinal cord injuries
              </li>
              <li className="TextPassage">
              Head and neck injuries
              </li>
              <li className="TextPassage">
              Whiplash
              </li>
              <li className="TextPassage">
              Soft tissue injuries
              </li>
              <li className="TextPassage">
              Back injuries
              </li>
              <li className="TextPassage">
              Fractures
              </li>
              <li className="TextPassage">
              Disfigurement
              </li>
              <li className="TextPassage">
              Burns              </li>

             
            </ul>
            <p className="TextPassage">
            We know that medical bills can quickly pile up after a serious injury. A herniated disc that requires surgery after acar accident can cost patients up to $150,000 or more. This does not include lost wages, rehabilitation expenses, and pain and suffering.

</p>
<p className="TextPassage">
You may need more compensation than you realize after suffering an injury in a car accident. As such, never sign a settlement from the auto insurance company without first speaking to an experienced Philadelphia car accident lawyer at The Levin Firm. We can ensure any settlement offer includes fair compensation that will cover all your medical expenses now and in the future.


</p>

                    </div>

















                    <h1 className="MainHeading">
                    Contact Our Philadelphia Car Accident Lawyers Today!

                    </h1>
                   
                   
                    <div className="ImageTextHolder">
                        <p className="TextPassage">
                        The Lawyer Connection represents clients injured in car accidents and the families of those tragically killed in car accidents. We are a full-service personal injury law firm. From the moment a client walks through our doors, our goal is to serve the client’s needs by recovering the maximum possible compensation for their injuries and losses. Every representation starts with our team getting to know all about our clients and their auto accident.





                        </p>
                        <p className="TextPassage">
Call our Philadelphia auto accident attorneys today at (717) 316-8324 or toll-free at (877) 825-8542, or contact or chat with us online to schedule a free consultation with a member of our team and discuss whether we can help you recover the compensation you deserve.




                        </p>

</div>






                </div>

                <div className="InputSegment">
                    <p className="RedText">
                        FOR CONSULTATION CALL (717) 316-8324
                    </p>
                    <input placeholder="Full Name" className="InputField" />

                    <input placeholder="Email" className="InputField" />

                    <input placeholder="Phone" className="InputField" />

                    <input placeholder="Phone" className="InputField" />

                    <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        rows="6"
                        cols="40"
                        className="InputFieldMsg"
                    />

                    <button className="SubmitButton">Submit</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CarAccidents;
